import styled from 'styled-components'
import {MdKeyboardArrowRight, MdKeyboardArrowDown} from 'react-icons/md'
import { Link } from 'react-scroll'


export const HomeContainer = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 background: #0c0c0c;
 z-index: 1;
 position: relative;
 height: 800px;

 @media screen and (max-width: 768px) {
   height: 700px;
 }
`

export const HomeBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const MyImage = styled.img`
 position: absolute;
 top: 0;
 left: 0;
 width: 700px;
 height: 100%;
 z-index: 2;

 @media screen and (max-width: 768px) {
  display: none;
 }
`

export const HomeBody = styled.div`
   background: rgb(3, 52, 86, 0.6);
   width: 100%;
   height. 100%;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 3;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: end;

   @media screen and (max-width: 768px) {

   }
`

export const HomeContent = styled.div`
    width: 55%;
    padding-left: 5%;

  @media screen and (max-width: 768px) {
    width: 90%;
    
  }
`


export const HomeH1 = styled.h1`
  font-size: 48px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  letter-spacing: 7px;

  @media screen and (max-width: 768px) {
    font-size: 34px;
  }

  @media screen and (max-width: 480px) {
    font-size: 30px;
    width: 300px;
  }
`

export const HomeP = styled.p`
 color: white;
 margin-top: 10px;
 font-size: 24px;
 font-weight: 300;

   @media screen and (max-width: 480px) {
    width: 300px;
  }
`

export const Button = styled(Link)`
   font-weight: 900;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   width: 200px;
   height: 50px;
   border-radius: 30px;
   background: lime;
   color: black;
   margin-top: 40px;
   scroll-behavior: smooth;

   &:hover {
    background: white;
   }

`

export const ArrowDown = styled(MdKeyboardArrowDown)`
  font-weight: 900;
  font-size: 20px;
  margin-left: 10px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  font-weight: 900;
  font-size: 20px;
  margin-left: 10px;
`