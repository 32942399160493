import styled from 'styled-components'
//import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS} from 'react-scroll'


export const Nav = styled.nav`
 background: rgb(3, 52, 86, .9);
 color: white;
 height: 80px;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 1rem;
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 z-index: 888;

 @media screen and (max-width: 780px) {
    transition: 0.8s all ease;
 }
`

export const NavContainer = styled.div`
 display: flex;
 justify-content: space-between;
 width: 100%;
 height: 80px;
 max-width: 1100px;
`

export const NavLogo = styled(LinkS)`
   cursor: pointer;
   justify-self: flex-start;
   display: flex;
   align-items: center;
   margin-left: 24px;
   scroll-behavior: smooth;
`

export const Image = styled.img`
   width: 40px;
   height: 40px;
`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.ul`
   text-decoration: none;
   display: flex;
   align-items: center;

   list-style: none;
   text-align: center;
   margin-right: -22px;

   @media screen and (max-width: 768px) {
    display: none;
   }

   
`

export const NavItem = styled.li`
  height: 80px;
`

export const NavLink = styled(LinkS)`
   color: white;
   text-decoration: none;
   text-transform: uppercase;
   height: 100%;
   cursor: pointer;
   display: flex;
   align-items: center;
   padding: 0 1rem;
   font-weight: bolder;
   scroll-behavior: smooth;
   
   &.active {
    border-bottom: 3px solid yellow;
   }
`