import React from 'react';
import {
    ContactContainer, 
    ContactBg,
    ContactVideoBg,
    ContactBody,
    ContactInfoHeader, 
    ContactInfo,
    InfoItem,
    Phone,
    Email,
    Computer,
    Address
} from './ContactElements'
import ContactVideo from '../../videos/video1.mp4';

function Contact() {
  return (
      <>
          <ContactContainer id='contact'>
               <ContactBg>
                <ContactVideoBg autoPlay loop muted src={ContactVideo} type='video/mp4'/>
              </ContactBg>
              <ContactBody>
                 <ContactInfoHeader> Get In <span className='touch'>Touch</span></ContactInfoHeader>
                 <ContactInfo>
                    <InfoItem> <Phone/>  +46762701202 </InfoItem>
                    <InfoItem> < Email/>  dmhaben@gmail.com </InfoItem>
                    <InfoItem> <Computer/> gohaben.com </InfoItem>
                    <InfoItem> <Address /> Stationsvägen 9C, SE-61174 Tystberga</InfoItem>
                 </ContactInfo>
              </ContactBody>
        </ContactContainer>
      </>
  )
}

export default Contact