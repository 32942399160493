const Data = [
    {
        id: 1,
        image: require('./images/image-1.png'),
        title: 'Build E-commerce Website with Javascript',
        descriptions: ['Build an e-commerce website with vanilla JavaScript, a user can order a product and send personal information to the server.'],
        repoLink: ' https://github.com/HabenMebrahtom/P5-Web-Dev-Kanap.git'
    },
    {
        id: 2,
        image: require('./images/image-2.png'),
        title: 'Build a secure Api for a review App',
        descriptions: ['Build a web application in which users can add their favorite sauces and like or dislike ones added by others with angular and NodeJS.',
            'The user can log in and signup with the secured password and the backend has to build according to OWASP security requirements.'],
        repoLink: 'https://github.com/HabenMebrahtom/Web-Developer-P6.git'
    },
    {
        id: 3,
        image: require('./images/image-3.png'),
        title: 'Open Forum for chatting and discussion App', descriptions: ['The website could let the company employee chat and discuss ideas among themselves. The employee can post discussion ideas and multimedia content, and reply to the discussion ideas.',
            'The application builds with ReactJS, NodeJS, and SQL servers and all web security measurements.'],
        repoLink: 'https://github.com/HabenMebrahtom/Web-Developer-P-7.git'
    },
    {
        id: 4,
        image: require('./images/image-4.png'),
        title: 'Integrate a mobile website with animations in CSS',
        descriptions: ['Develop a navigation structure for a website', 'Implement design effects using CSS & Sass', 'Ensure design consistency on a website'],
        repoLink: 'https://github.com/HabenMebrahtom/HabenMebrahtom_03_20200923.git'
    },
]


export default Data