import React from 'react'
import { Nav, NavLogo, NavContainer, Image, MobileIcon, NavMenu, NavItem, NavLink } from './NavbarElements'
import image from '../../images/logo.png';
import {FaBars} from 'react-icons/fa'


function Navbar({toggle}) {
  return (
      <>
        <Nav>
          <NavContainer>
                  <NavLogo to="home">
                      <Image src={image}></Image>
                  </NavLogo>
                  <MobileIcon onClick={toggle}>
                      <FaBars />
                  </MobileIcon>
                  <NavMenu>
                      <NavItem> 
                          <NavLink to="home">Home</NavLink>
                      </NavItem>
                      <NavItem> 
                          <NavLink to="about">About</NavLink>
                      </NavItem>
                      <NavItem> 
                          <NavLink to="project">Projects</NavLink>
                      </NavItem>
                      <NavItem> 
                          <NavLink to="contact">Contact</NavLink>
                      </NavItem>
                  </NavMenu>
          </NavContainer>
        </Nav>
      
      </>
  )
}

export default Navbar