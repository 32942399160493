import React, {useState} from 'react'
import { HomeContainer, HomeBg, VideoBg, MyImage, HomeBody, HomeContent, HomeH1, HomeP, Button, ArrowDown, ArrowRight } from './HomeElement';
import Video from '../../videos/video.mp4';
import image from '../../images/picwish.png'


function HomeSection() {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
  return (
      <>
          <HomeContainer id="home">
              <HomeBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
              </HomeBg>
              <MyImage src={image} alt="my picture" />
              <HomeBody>
                  <HomeContent>
                    <HomeH1>I'm <br></br>Haben <br></br> Mebrahtom</HomeH1>
                      <HomeP>Junior MERN Stack Developer</HomeP>
                      <Button to="about" onMouseEnter={onHover} onMouseLeave={onHover}>
                        View my work {hover ? <ArrowDown /> : <ArrowRight/>}
                    </Button>
                  </HomeContent>
              </HomeBody>
          </HomeContainer>
      </>
  )
}

export default HomeSection