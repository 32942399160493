import React from 'react'
import { FooterContainer, FooterList, FooterResume, FooterRow, FooterLink, LinkBtn, LinkList, LinkednIcon, GithubIcon } from './FooterElements'
import pdf from '../../images/Resume.pdf';


function Footer() {
  return (
      <>
          <FooterContainer>
              <FooterRow>
                  <FooterResume>
                      <LinkBtn>
                          <LinkList to={pdf} target="_blank" download='Resume.pdf'>Download Resume</LinkList>
                      </LinkBtn>
                  </FooterResume>
                  <FooterList>
                      <FooterLink href="https://www.linkedin.com/in/haben-mebrahtom-681159169"><LinkednIcon /></FooterLink>
                       <FooterLink href="https://github.com/HabenMebrahtom"><GithubIcon /></FooterLink>
                  </FooterList>
              </FooterRow>
          </FooterContainer>
      </>
  )
}

export default Footer