import React from 'react'
import {
    AboutContainer,
    AboutBg,
    AboutBgImage,
    AboutBody,
    AboutContent,
    AboutH2,
    AboutContentRow,
    AboutBullet,
    AboutBulletFastIcon,
    AboutBulletDynamicIcon,
    AboutBulletResponsiveIcon,
    AboutDescriptionTitle,
    AboutDescriptionContent,
    SkillContent,
    SkillHeader,
    SkillRow,
    SkillCircle,
    SkillCircleOuter,
    SkillCircleInner,
    SkillPercentage,
    SkillName,
    SkillBullet,
    SVG
} from './AboutElements'
import Image from '../../images/about-bg.jpg'

function About() {
  return (
      <>
        <AboutContainer id='about'>
            <AboutBg>
                  <AboutBgImage src={Image} />
            </AboutBg>
              <AboutBody>
                  <AboutContent>
                     <AboutH2>
                       About
                      </AboutH2> 
                      <AboutContentRow>
                          <AboutBullet>
                              <AboutBulletFastIcon />
                              <AboutDescriptionTitle>Fast</AboutDescriptionTitle>
                              <AboutDescriptionContent>Fast load times and lag free interaction, my highest priority</AboutDescriptionContent>
                          </AboutBullet>
                          <AboutBullet>
                              <AboutBulletDynamicIcon />
                              <AboutDescriptionTitle>Dynamic</AboutDescriptionTitle>
                              <AboutDescriptionContent>Websites don't have to be static, I love making pages come to life.</AboutDescriptionContent>
                          </AboutBullet>
                          <AboutBullet>
                              <AboutBulletResponsiveIcon />
                              <AboutDescriptionTitle>Responsive</AboutDescriptionTitle>
                              <AboutDescriptionContent>My layouts will work on any device, big or small.</AboutDescriptionContent>
                         </AboutBullet>
                      </AboutContentRow> 
                  </AboutContent>
                  <SkillContent>
                      <SkillHeader>Skills</SkillHeader>
                      <SkillRow>
                              <SkillBullet>
                                  <SkillCircle>
                                      <SkillCircleOuter>
                                          <SkillCircleInner>
                                             <SkillPercentage>
                                                70%
                                              </SkillPercentage>
                                          </SkillCircleInner>
                                  </SkillCircleOuter>
                                  <SVG xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                        <stop offset="0%" stop-color="#e91e63" />
                                        <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="50" cy="50" r="42" stroke-linecap="round"  id="html" className='circular'/>
                                   </SVG>
                                  </SkillCircle>
                                  <SkillName>
                                      HTML
                                  </SkillName>
                              </SkillBullet>
                              <SkillBullet>
                                  <SkillCircle>
                                      <SkillCircleOuter>
                                          <SkillCircleInner>
                                              <SkillPercentage>
                                                70%
                                              </SkillPercentage>
                                          </SkillCircleInner>
                                  </SkillCircleOuter>
                                  <SVG xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                        <stop offset="0%" stop-color="#e91e63" />
                                        <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                      <circle cx="50" cy="50" r="42" stroke-linecap="round" id='css' />
                                   </SVG>
                                  </SkillCircle>
                                  <SkillName>
                                      CSS
                                  </SkillName>
                              </SkillBullet>
                              <SkillBullet>
                                  <SkillCircle>
                                      <SkillCircleOuter>
                                          <SkillCircleInner>
                                              <SkillPercentage>
                                                65%
                                              </SkillPercentage>
                                          </SkillCircleInner>
                                  </SkillCircleOuter>
                                  <SVG xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px" id="">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                        <stop offset="0%" stop-color="#e91e63" />
                                        <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="50" cy="50" r="42" stroke-linecap="round"  id="javascript"/>
                                   </SVG>
                                  </SkillCircle>
                                  <SkillName>
                                      JavaScript
                                  </SkillName>
                              </SkillBullet>
                              <SkillBullet>
                                  <SkillCircle>
                                      <SkillCircleOuter>
                                          <SkillCircleInner>
                                            <SkillPercentage>
                                                50%
                                              </SkillPercentage>  
                                          </SkillCircleInner>
                                  </SkillCircleOuter>
                                  <SVG xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                        <stop offset="0%" stop-color="#e91e63" />
                                        <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="50" cy="50" r="42" stroke-linecap="round"  id="nodejs"/>
                                   </SVG>
                                  </SkillCircle>
                                  <SkillName>
                                      Node.js
                                  </SkillName>
                              </SkillBullet>
                              <SkillBullet>
                                  <SkillCircle>
                                      <SkillCircleOuter>
                                          <SkillCircleInner>
                                              <SkillPercentage>
                                                50%
                                              </SkillPercentage>
                                          </SkillCircleInner>
                                  </SkillCircleOuter>
                                  <SVG xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                        <stop offset="0%" stop-color="#e91e63" />
                                        <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="50" cy="50" r="42" stroke-linecap="round"  id="sql"/>
                                   </SVG>
                                  </SkillCircle>
                                  <SkillName>
                                      SQL
                                  </SkillName>
                              </SkillBullet>
                              <SkillBullet>
                                  <SkillCircle>
                                      <SkillCircleOuter>
                                          <SkillCircleInner>
                                              <SkillPercentage>
                                                60%
                                              </SkillPercentage>
                                          </SkillCircleInner>
                                  </SkillCircleOuter>
                                  <SVG xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                        <stop offset="0%" stop-color="#e91e63" />
                                        <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="50" cy="50" r="42" stroke-linecap="round"  id="reactjs"/>
                                   </SVG>
                                  </SkillCircle>
                                  <SkillName>
                                      React.js
                                  </SkillName>
                              </SkillBullet>
                              <SkillBullet>
                                  <SkillCircle>
                                      <SkillCircleOuter>
                                          <SkillCircleInner>
                                              <SkillPercentage>
                                                40%
                                              </SkillPercentage>
                                          </SkillCircleInner>
                                  </SkillCircleOuter>
                                  <SVG xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                        <stop offset="0%" stop-color="#e91e63" />
                                        <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="50" cy="50" r="42" stroke-linecap="round"  id="vuejs"/>
                                   </SVG>
                                  </SkillCircle>
                                  <SkillName>
                                      Vue.js
                                  </SkillName>
                              </SkillBullet>
                          </SkillRow>
                  </SkillContent>
              </AboutBody>  
        </AboutContainer>
      </>
  )
}

export default About