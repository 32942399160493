import styled from 'styled-components';
import {MdPhone,  MdEmail, MdComputer, MdMapsHomeWork } from 'react-icons/md';


export const ContactContainer = styled.section`
  width: 100%;
  height: 600px;
  background: #0c0c0c;
  z-index: 1;
  position: relative;
`

export const ContactBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ContactVideoBg = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ContactBody = styled.div`
   width: 100%;
   height. 100%;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 3;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: end;
`

export const ContactInfoHeader = styled.p`
  font-size: 24px;
  font-weight: 900;
  margin: 50px auto;
  color: white;

  .touch {
    color: orange;
  }
`
export const ContactInfo = styled.div`
  width: 400px;
  margin: 0 auto;

  @media screen and (max-width: 780px) {
    width: 300px;
  }
`
export const InfoItem = styled.div`
  background: rgb(3, 52, 86, 0.8);
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px 30px;
`

export const Phone = styled(MdPhone)`
   font-weight: bolder;
   font-size: 20px;
   color: orange;
   margin-right: 30px;
`
export const Email = styled(MdEmail)`
   font-weight: bolder;
   font-size: 20px;
   color: orange;
   margin-right: 30px;
`

export const Computer = styled(MdComputer)`
   font-weight: bolder;
   font-size: 20px;
   color: orange;
   margin-right: 30px;
`

export const Address = styled( MdMapsHomeWork)`
   font-weight: bolder;
   font-size: 20px;
   color: orange;
   margin-right: 30px;
`




