import styled from 'styled-components'

export const ProjectsContainer = styled.section`
 width: 100%;
 height: 800px;
 background-image: url(${require('../../images/projects-bg.jpg')});
 background-position: center;
 background-repeat: no-repeat;
`


export const ProjectsHeader = styled.h2`
  font-weight: 900;
  font-size: 34px;
  padding-top: 100px;
  padding-bottom: 40px;
  text-align: center;
  color: white;
`


export const ProjectContent = styled.div`
  display: flex;
  justify-content: center;
  z-index: 9;

  @media screen and (max-width: 768px) {
    display: grid;
  }
`

export const ProjectImage = styled.img`
  width: 45%;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
`

export const ProjectDescriptionSection = styled.div`
  width: 45%;
  padding: 20px;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
`

export const ProjectName = styled.h3`
  margin-top: 5px;
`

export const ProjectDescription = styled.ul`
  margin: 10px 0;
`

export const Description = styled.li`
  margin: 10px 20px;;
`
export const ProjectLink = styled.a`
   text-decoration: none;
   padding: 10px 20px;
   background-color: mediumblue;
   color: white;
   margin: 40px 10px;
`
