import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./styles.css";


// import required modules
import { Pagination, Navigation, EffectFade } from "swiper";
import {
    ProjectsContainer,
    ProjectsHeader,
    ProjectContent,
    ProjectDescriptionSection,
    ProjectDescription,
    ProjectImage,
    ProjectName,
    ProjectLink,
    Description
} from "./ProjectsElements";
import Datas from './Data'


export default function Projects() {
  return (
    <ProjectsContainer id="project">
       <ProjectsHeader>Projects</ProjectsHeader>
            
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    effect={"fade"}  
                    modules={[Pagination, Navigation, EffectFade]}
                    className="mySwiper"
                >
              {
                  Datas.map(data => (
                       <SwiperSlide  key={data.id}>
                            <ProjectContent>
                                <ProjectImage src={data.image}>
                                </ProjectImage>
                                <ProjectDescriptionSection>
                                    <ProjectName>{data.title} </ProjectName>
                                    <ProjectDescription>
                                       {
                                          data.descriptions.map((desc, index) => {
                                            return  <Description key={index}>{desc}</Description>
                                          })
                                      }
                                   </ProjectDescription>
                                  <ProjectLink href={data.repoLink}>Github repository </ProjectLink>
                                </ProjectDescriptionSection>
                            </ProjectContent>
                    </SwiperSlide>
                  ))}
                </Swiper>
    </ ProjectsContainer>
  );
}
