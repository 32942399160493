import styled from 'styled-components';
import { BsSpeedometer2 } from 'react-icons/bs'
import { SiReactos } from 'react-icons/si'
import { MdPhonelink } from 'react-icons/md'
 

export const AboutContainer = styled.section`
   height: 880px;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #0c0c0c;
   z-index: 1;
   transition: 3s ease-in-out;
`


export const AboutBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const AboutBgImage = styled.img`
    width: 100%;
    height: 100%;
`

export const AboutBody = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, .8)
`
export const AboutContent = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
`

export const AboutH2 = styled.h2`
 color: white;
 font-weight: 700;
 font-size: 34px;
 text-align: center;
 padding-top: 100px;
 animation: 2s slide ease-in-out;

 @keyframes slide {
   0% {
    transform: translateX(-100%);
   } 
   100% {
    transform: translateX(0);
   }
 }

 @media screen and (max-width: 768px) {
    font-weight: 700;
    font-size: 24px;
    padding-top: 10px;
 }
`

export const AboutContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: evenly;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }

`

export const AboutBullet = styled.div`
  width: 250px;
  height: 250px;
  text-align: center;
  color: white;
  margin: auto;
  animation: 5s appear ease-in-out;

  @keyframes appear {
    0% {
        opacity: 0;
    }
    20% {
        opacity: .2;
    }
    40% {
        opacity: .4;
    }
    60% {
        opacity: .6
    }
    80% {
        opacity: .8;
    }
    100% {
        opacity: 1;
    }
  }

  @media screen and (max-width: 480px) {
    width: 150px;
    height: 150px;
  }
`
export const AboutBulletFastIcon = styled(BsSpeedometer2)`
  color: orange;
  width: 70px;
  height: 70px;

   @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

export const AboutBulletDynamicIcon = styled(SiReactos)`
  color: yellow;
  width: 70px;
  height: 70px;

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

export const AboutBulletResponsiveIcon = styled(MdPhonelink)`
  color: aqua;
  width: 70px;
  height: 70px;

 @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`
export const AboutDescriptionTitle = styled.h3`
  margin-top: 10px;
  font-size: 34px;
  font-weight: 700;


 @media screen and (max-width: 768px) {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
  }
`

export const AboutDescriptionContent = styled.p`

 margin-top: 10px;
 @media screen and (max-width: 768px) {
   margin-top: 5px;
  }
`
    
export const SkillContent = styled.div`
  width: 100%;
  margin-top: 20px;
`
export const SkillHeader = styled.h2`
  font-weight: 700;
  text-align: center;
  color: white;
  margin: 10px auto;
`

export const SkillRow = styled.div`
    display: flex; 
    justify-content: evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 20px auto;

    @media screen and (max-width: 768px) {
        margin: 10px auto;
        width: 100%;
    }
`

export const SkillBullet = styled.div`
  height: 120px;
  width: 120px;
  text-align: center;
  margin: 10px auto;
`

export const SkillCircle = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  margin: auto;
`

export const SkillCircleOuter = styled.div`
  height: 100px;
  width: 100px; 
  border-radius: 50%;
  padding: 13px;
  box-shadow: 6px 6px 10px 1px rgba(192, 192, 192, 0.15),
              -6px -6px 10px -1px rgba(255, 255, 0, 0.15);
`

export const SkillCircleInner = styled.div`
 width: 75px;
 height: 75px;
 border-radius: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
 box-shadow: inset 4px 4px 6px -1px rgba(192, 192, 192, 0.2),
              inset -4px -4px 6px -1px rgba(255, 255, 0, 0.7),
                    -0.5px -0.5px 0px rgba(192, 192, 192, 1),
                    0.5px 0.5px 0px rgba(192, 192, 192, 0.15),
                    0px 12px 10px -10px rgba(192, 192, 192, 0.05);
`

export const SkillPercentage = styled.div`
   color: white;
   font-weight: 
`

export const SkillName = styled.p`
  color: white;
  font-weight: 700;
  margin: 5px;
`

export const SVG = styled.svg`
   position: absolute;
   top: 0; 
   left: 0;


  circle {
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 13px;
    stroke-dasharray: 264;
    stroke-dashoffset: 264;
  }

  #html {
    animation: html 2s linear forwards;
  }

  #css {
    animation: css 2s linear forwards;
  }

  #javascript {
    animation: javascript 2s linear forwards;
  }

  #nodejs {
    animation: nodejs 2s linear forwards;
  }

  #sql {
    animation: sql 2s linear forwards;
  }

  #reactjs {
    animation: reactjs 2s linear forwards;
  }

  #vuejs{
    animation: vuejs 2s linear forwards;
  }

@keyframes  html {
    100% {
         stroke-dashoffset: 79; 
    }
}

@keyframes  css {
    100% {
         stroke-dashoffset: 79; 
    }
}

@keyframes  javascript {
    100% {
         stroke-dashoffset: 119; 
    }
}

@keyframes  nodejs {
    100% {
         stroke-dashoffset: 132; 
    }
}

@keyframes  sql {
    100% {
         stroke-dashoffset: 132; 
    }
}

@keyframes  reactjs {
    100% {
         stroke-dashoffset: 106; 
    }
}

@keyframes  vuejs {
    100% {
         stroke-dashoffset: 158; 
    }
  }
`