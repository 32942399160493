import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { BsLinkedin, BsGithub } from 'react-icons/bs';

export const FooterContainer = styled.section`
  width: 100%;
  height: 200px;
  background-color: rgb(0, 0, 0, .7);
  color: white;
`

export const FooterRow = styled.div`
 width: 100%;
 height: 100%;
 display: flex;

  @media screen and (max-width: 768px) {
    display: block;

  }
`

export const FooterResume = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50%;

   @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: start;
    padding: 20px;
  }
`

export const LinkBtn = styled.button`
    width: 200px;
    height: 40px;
    border-radius: 5px;
    border-color: transparent;

    &:hover {
      background-color: orange;
    }
` 

export const LinkList = styled(Link)`
    text-decoration: none;
    font-size: 18px;
    font-weight: 300;
    color: black;

    &:hover {
      color: white;
    }
`

export const FooterList = styled.ul`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: start;
    padding: 20px;
  }
`
export const FooterLink = styled.a`
     color: white;
     margin-right: 30px;
`

export const LinkednIcon = styled(BsLinkedin)`
  font-size: 30px;

  &:hover {
    color: gold;
  }

   @media screen and (max-width: 768px) {
    font-size: 25px;
   }
`

export const GithubIcon = styled(BsGithub)`
   font-size: 30px;
   margin-left: 10px;

   &:hover {
     color: gold;
   }

   @media screen and (max-width: 768px) {
    font-size: 25px;
   }
`